import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import axios from 'utils/axios';

// initial state
const initialState = {
    undos: []
};

// ==============================|| SLICE - UNDOS ||============================== //

const undo = createSlice({
    name: 'undo',
    initialState,
    reducers: {
        // get dashboard undo
        getUndosSuccess(state, action) {
            state.undos = action.payload;
        }
    }
});

export default undo.reducer;

export function getUndos() {
    return async () => {
        try {
            const response = await axios.get('/api/undos');
            dispatch(undo.actions.getUndosSuccess(response.data));
        } catch (error) {
            console.error(error);
        }
    };
}
