// eslint-disable-no-nested-ternary
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getPendingDestructions, getPendingExpeditionSamples } from 'store/slices/transactionSlice';
import { set } from 'lodash';

// notification status options
const status = [
    {
        value: 'all',
        label: (
            <>
                <FormattedMessage id="all-notifications" />
            </>
        )
    },
    {
        value: 'destruction',
        label: 'Destruction'
    },
    {
        value: 'expedition',
        label: 'Expedition'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const [newDestructionEvent, setNewDestructionEvent] = useState(false);
    const [newExpeditionEvent, setNewExpeditionEvent] = useState(false);
    const [pendingDestructions, setPendingDestructions] = useState([]);
    const [pendingExpeditions, setPendingExpeditions] = useState([]);
    const [allNotifications, setAllNotifications] = useState([]);
    const [userId, setUserId] = useState(0);
    const [optionChosen, setOptionChosen] = useState(status[0].value);

    const { pendingdestructions, pendingexpeditionsamples } = useSelector((state) => state.transactions);

    const destructionChannel = window.Pusher.subscribe('new-pending-destruction');
    destructionChannel.bind('App\\Events\\NewPendingDestruction', (data) => {
        if (data.destruction.user_id === parseInt(localStorage.getItem('user_id'), 10)) {
            setNewDestructionEvent(true);
        }
    });
    const sendToExpeditionChannel = window.Pusher.subscribe(`expedition-channel.${localStorage.getItem('user_id')}`);
    sendToExpeditionChannel.bind('App\\Events\\NewExpeditionEvent', (data) => {
        setNewExpeditionEvent(true);
    });
    const executeExpeditionChannel = window.Pusher.subscribe(`execute-expedition-channel.${localStorage.getItem('user_id')}`);
    executeExpeditionChannel.bind('App\\Events\\ExecutingExpeditionEvent', (data) => {
        setNewExpeditionEvent(true);
    });

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (pendingdestructions.length !== pendingDestructions.length || pendingDestructions.length === 0) {
            dispatch(getPendingDestructions());
        }
        if (pendingexpeditionsamples.length !== pendingExpeditions.length || pendingExpeditions.length === 0) {
            dispatch(getPendingExpeditionSamples());
        }
        return () => {};
    }, []);

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
        setUserId(parseInt(localStorage.getItem('user_id'), 10));
    }, [open]);

    useEffect(() => {
        if (newDestructionEvent) {
            dispatch(getPendingDestructions());
        }
        if (newExpeditionEvent) {
            dispatch(getPendingExpeditionSamples());
        }
    }, [newDestructionEvent, newExpeditionEvent]);

    useEffect(() => {
        if (pendingdestructions.length > 0) {
            setNewDestructionEvent(false);
            const destructions = pendingdestructions.filter((destruction) => destruction.user_id === userId);
            destructions.sort((a, b) => new Date(b.date_sent_to_destruction) - new Date(a.date_sent_to_destruction));
            setPendingDestructions(destructions);
        }
        if (pendingexpeditionsamples.length > 0) {
            setNewExpeditionEvent(false);
            const expeditionSamples = pendingexpeditionsamples.filter((expedition) => expedition.user_id === userId);
            expeditionSamples.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setPendingExpeditions(expeditionSamples);
        }
        if (pendingdestructions.length > 0 || pendingexpeditionsamples.length > 0) {
            const allNotif = [...pendingdestructions, ...pendingexpeditionsamples];

            allNotif.sort((a, b) => {
                // Use a consistent date field, or handle the case where one might not exist
                const dateA = new Date(a.date_sent_to_destruction || a.created_at);
                const dateB = new Date(b.date_sent_to_destruction || b.created_at);
                return dateB - dateA;
            });

            setAllNotifications(allNotif);
        }
    }, [pendingdestructions, pendingexpeditionsamples]);

    const handleChange = (event) => setValue(event?.target.value);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Badge
                    color={allNotifications.length > 0 ? 'warning' : 'default'}
                    invisible={allNotifications.length === 0}
                    badgeContent={allNotifications.length}
                >
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="20px" />
                    </Avatar>
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography variant="subtitle1">
                                                                {(() => {
                                                                    if (optionChosen === 'destruction')
                                                                        return <FormattedMessage id="pending-destructions" />;
                                                                    if (optionChosen === 'expedition')
                                                                        return <FormattedMessage id="pending-expeditions" />;
                                                                    return <FormattedMessage id="all-notifications" />;
                                                                })()}
                                                            </Typography>
                                                            <Chip
                                                                size="small"
                                                                label={(() => {
                                                                    if (optionChosen === 'destruction') return pendingDestructions.length;
                                                                    if (optionChosen === 'expedition') return pendingExpeditions.length;
                                                                    return allNotifications.length;
                                                                })()}
                                                                sx={{
                                                                    color: theme.palette.background.default,
                                                                    bgcolor: theme.palette.warning.dark
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                                >
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Box sx={{ px: 2, pt: 0.25 }}>
                                                                <TextField
                                                                    id="outlined-select-currency-native"
                                                                    select
                                                                    fullWidth
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        setOptionChosen(e.target.value);
                                                                        handleChange(e);
                                                                    }}
                                                                    SelectProps={{
                                                                        native: true
                                                                    }}
                                                                >
                                                                    {status.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider sx={{ my: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    {(() => {
                                                        if (optionChosen === 'destruction')
                                                            return (
                                                                <NotificationList
                                                                    data={{ type: 'destruction', data: pendingDestructions }}
                                                                />
                                                            );
                                                        if (optionChosen === 'expedition')
                                                            return (
                                                                <NotificationList data={{ type: 'expedition', data: pendingExpeditions }} />
                                                            );
                                                        return <NotificationList data={{ type: 'all', data: allNotifications }} />;
                                                    })()}
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {/*  <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Button size="small" disableElevation>
                                                View All
                                            </Button>
                                        </CardActions> */}
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
