import { memo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import menuItem from 'menu-items';
import NavGroup from './NavGroup';
import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';
import useConfig from 'hooks/useConfig';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [Id, setId] = useState(null);
    useEffect(() => {
        const roleId = localStorage.getItem('roleId');
        setId(roleId);
    }, []);
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();
    const filteredSettingsChildren = menuItem.items[3].children.filter((child) => {
        if (child.id === 'companies' || child.id === 'reports') {
            return Id === 1;
        }
        return true;
    });
    let visibleMenuItems = [];
    visibleMenuItems = [...menuItem.items];
    visibleMenuItems[3] = {
        ...menuItem.items[3],
        children: filteredSettingsChildren
    };
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;
    let lastItemIndex = visibleMenuItems.length - 1;
    let remItems = [];
    let lastItemId;

    if (lastItem && lastItem < visibleMenuItems.length) {
        lastItemId = visibleMenuItems[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = visibleMenuItems.slice(lastItem - 1, visibleMenuItems.length).map((item) => ({
            title: item.title,
            elements: item.children
        }));
    }
    const navItems = visibleMenuItems.slice(0, lastItemIndex + 1).map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    return <>{navItems}</>;
};

export default memo(MenuList);
