import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn, validateToken } = useAuth();
    const [isValid, setIsValid] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
        const checkToken = async () => {
            const isTokenValid = await validateToken();
            setIsValid(isTokenValid);
        };

        /* checkToken(); */
    }, [isLoggedIn, navigate]);

    /* if (!isValid) {
        navigate('login', { replace: true });
    }
 */
    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
