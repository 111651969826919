import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AuthorizationGuard({ allowedIds, children }) {
    const navigate = useNavigate();

    useEffect(() => {
        // Decode the token and get the user id
        const id = localStorage.getItem('roleId');
        if (!allowedIds.includes(parseFloat(id))) {
            navigate('/dashboard/default');
        }
    }, [allowedIds, navigate]);

    return children;
}

export default AuthorizationGuard;
