// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconAlertCircle, IconTruckDelivery, IconFileShredder, IconArrowForward } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

const ListItemComponent = ({ type, data, handleClick }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    // Conditionally set the icon and primary text based on the type
    let icon;
    let primaryText;
    let colorKey = 'default';
    let urlRedirection = '';
    if (!data) return null;
    if (data?.weight) {
        icon = <IconFileShredder stroke={1.5} size="20px" />;
        primaryText = 'Pending Destruction';
        colorKey = 'error';
        urlRedirection = 'destruction';
    } else if (data?.net_weight) {
        icon = <IconTruckDelivery stroke={1.5} size="20px" />;
        primaryText = 'Pending Expedition';
        colorKey = 'warning';
        urlRedirection = 'expedition';
    }
    const color = theme.palette[colorKey];
    return (
        <>
            <ListItem alignItems="center">
                <ListItemAvatar>
                    <Avatar
                        sx={{
                            color: color.dark, // Use dark shade of the color
                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : color.light, // Use light shade for light mode
                            border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                            borderColor: color.main // Use main color
                        }}
                    >
                        {icon}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        '& .MuiTypography-root': {
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[600]
                        }
                    }}
                    primary={data?.batch?.identifier || 'N/A'}
                />
                <Typography variant="caption" display="block" gutterBottom>
                    {data.date_sent_to_destruction || data.created_date || 'N/A'}
                </Typography>
            </ListItem>
            <Grid container direction="row" className="list-container" mb={2}>
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="subtitle2">
                        {(() => {
                            if (data?.count > 0) return `${data?.count} plante`;
                            if (data?.net_weight) return `${data?.net_weight} kg`;
                            if (data?.weight) return `${data?.weight} kg`;
                            return 'N/A';
                        })()}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {/* icon to go to details */}
                    <IconButton
                        onClick={() => {
                            navigate(`/transactions/${urlRedirection}`);
                        }}
                        sx={{
                            color: theme.palette.primary.main,
                            '&:hover': {
                                color: theme.palette.primary.dark
                            }
                        }}
                    >
                        <IconArrowForward stroke={2} size="20px" />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ data }) => {
    const theme = useTheme();

    const handleClick = (data) => {
        // console.log(data);
    };

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {data.data.map((item, index) => (
                <ListItemWrapper key={index}>
                    <ListItemComponent type={data.type} data={item} handleClick={handleClick} />
                    <Divider />
                </ListItemWrapper>
            ))}
        </List>
    );
};

export default NotificationList;
