import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Box, Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
            <Link component={RouterLink} to={DASHBOARD_PATH} aria-label="theme-logo">
                <Logo />
            </Link>
        </Box>
    </>
);

export default LogoSection;
