import { useContext } from 'react';
import AuthContext from 'contexts/JWTContext';
import axiosServices from 'utils/axios';

const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) throw new Error('context must be used inside a provider');

    // Function to validate the token by calling an endpoint
    const validateToken = async () => {
        try {
            const response = await axiosServices.post('/auth/validate-token');
            return response.status === 200; // Assume the endpoint returns 200 if valid
        } catch (error) {
            return false;
        }
    };

    return { ...context, validateToken };
};

export default useAuth;
