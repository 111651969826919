import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'store';
import { getDropdownList } from 'store/slices/user';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        // Name of the component
        MuiTypography: {
            styleOverrides: {
                root: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }
            }
        }
    }
});

const MyDropdown = () => {
    const { dropdownlist } = useSelector((state) => state.user);
    const [selectedInstallationId, setSelectedInstallationId] = useState('');
    const [options, setOptions] = useState([]);
    const [installationAddress, setinstallationAddress] = useState('');
    const [showFullNames, setShowFullNames] = useState(false);

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getDropdownList());
    }, [dispatch]);

    React.useEffect(() => {
        setOptions(dropdownlist);
    }, [dropdownlist]);

    const handleSelectClick = () => {
        setShowFullNames(!showFullNames);
    };

    useEffect(() => {
        const address = options[0]?.installations[0]?.installation_adress || options[0]?.address;
        setinstallationAddress(address);
    }, [options]);

    const renderValue = () => {
        const installation = options[0]?.installations[0]?.name || options[0]?.name;
        return (
            <Typography variant="subtitle1" fontWeight="bold" component="span">
                {installation}
            </Typography>
        );
    };

    return (
        <Select
            onOpen={handleSelectClick}
            onClose={handleSelectClick}
            value={selectedInstallationId}
            renderValue={renderValue}
            displayEmpty
            sx={{
                width: '300px',
                height: '50px',
                ml: 3,
                zIndex: '0'
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }}
        >
            <MenuItem key={1} value={1}>
                {installationAddress}
            </MenuItem>
        </Select>
    );
};

export default MyDropdown;
