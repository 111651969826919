// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import UserReducer from './slices/user';
import TransactionReducer from './slices/transactionSlice';
import batchReducer from './slices/batch';
import reportsReducer from './slices/reports';
import adminReducer from './slices/admin';
import undos from './slices/undos';
import settings from './slices/settings';
import account from './accountReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    user: UserReducer,
    transactions: TransactionReducer,
    batch: batchReducer,
    reports: reportsReducer,
    admin: adminReducer,
    undos,
    settings,
    account
});

export default reducer;
