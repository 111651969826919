// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    weeklypassword: ''
};

const slice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        getWeeklyPasswordSuccess(state, action) {
            state.weeklypassword = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getWeeklyPassword() {
    return async () => {
        try {
            const response = await axios.get(`/api/weekly-password`);
            dispatch(slice.actions.getWeeklyPasswordSuccess(response.data));
        } catch (error) {
            console.log('ERROR FETCHING SINGLE SUBLOT', error);
        }
    };
}
