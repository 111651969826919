// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconPackage, IconChecklist } from '@tabler/icons';

// constant
const icons = {
    IconPackage,
    IconChecklist
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const storage = {
    id: 'storage-docs-roadmap',
    title: <FormattedMessage id="storage" />,
    icon: icons.IconHelp,
    type: 'group',
    children: [
        {
            id: 'vrac',
            title: 'Vrac',
            type: 'item',
            url: '/storage/vrac',
            icon: icons.IconPackage,
            breadcrumbs: false
        },
        {
            id: 'retentions',
            title: 'Rétentions',
            type: 'item',
            url: '/storage/retentions',
            icon: icons.IconChecklist,
            breadcrumbs: false
        }
    ]
};

export default storage;
