// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconSeeding, IconLeaf, IconPlant, IconPlant2, IconFlower, IconFlowerOff, IconStack } from '@tabler/icons';

// constant
const icons = {
    IconSeeding,
    IconLeaf,
    IconPlant,
    IconPlant2,
    IconFlower,
    IconFlowerOff,
    IconStack
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const production = {
    id: 'production-docs-roadmap',
    title: 'Production',
    icon: icons.IconHelp,
    type: 'group',
    children: [
        /* {
            id: 'graines',
            title: <FormattedMessage id="seeds" />,
            type: 'item',
            url: '/production/graines',
            icon: icons.IconSeeding,
            breadcrumbs: false
        }, */
        {
            id: 'available-lots',
            title: (
                <>
                    <FormattedMessage id="lots" /> <FormattedMessage id="available" />
                </>
            ),
            type: 'item',
            url: '/production/lots-disponibles',
            icon: icons.IconStack,
            breadcrumbs: false
        },
        {
            id: 'plantes-végétatives',
            title: <FormattedMessage id="vegetative-plant" />,
            type: 'item',
            url: '/production/plantes-végétatives',
            icon: icons.IconLeaf,
            breadcrumbs: false
        },
        {
            id: 'plantes-entières',
            title: <FormattedMessage id="whole-plant" />,
            type: 'item',
            url: '/production/plantes-entières',
            icon: icons.IconPlant,
            breadcrumbs: false
        },
        {
            id: 'cannabis-frais',
            title: <FormattedMessage id="fresh-cannabis" />,
            type: 'item',
            url: '/production/cannabis-frais',
            icon: icons.IconPlant2,
            breadcrumbs: false
        },
        {
            id: 'dried-cannabis',
            title: <FormattedMessage id="dried-cannabis" />,
            type: 'item',
            url: '/production/cannabis-séché',
            icon: icons.IconFlower,
            breadcrumbs: false
        },
        {
            id: 'non-florifière',
            title: <FormattedMessage id="non-flower" />,
            type: 'item',
            url: '/production/non-florifière',
            icon: icons.IconFlowerOff,
            breadcrumbs: false
        }
    ]
};

export default production;
