// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    importationdata: {},
    transaction: '',
    transactions: [],
    importation: '',
    importations: [],
    installationimportations: [],
    destructions: [],
    pendingdestructions: [],
    destruction: '',
    destructionreasons: [],
    batchnonflowers: [],
    nonflowers: [],
    nonflower: '',
    pendingexpeditionsamples: [],
    expeditionsamples: [],
    expeditionsample: ''
};

const slice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        getAllTransactionsSuccess(state, action) {
            state.transactions = action.payload;
        },
        getSingleTransactionsSuccess(state, action) {
            state.transaction = action.payload;
        },
        getAllImportationsSuccess(state, action) {
            state.importations = action.payload;
        },
        getAllInstallationImportationsSuccess(state, action) {
            state.installationimportations = action.payload;
        },
        getSingleImportTransactionSuccess(state, action) {
            state.importation = action.payload;
        },
        updateImportationDataSuccess(state, action) {
            state.importationdata = action.payload;
        },
        getSingleDestructionSuccess(state, action) {
            state.destruction = action.payload;
        },
        getAllDestructionsSuccess(state, action) {
            state.destructions = action.payload;
        },
        getAllDestructionReasonsSuccess(state, action) {
            state.destructionreasons = action.payload;
        },
        getBatchAllNonFlowerSuccess(state, action) {
            state.batchnonflowers = action.payload;
        },
        getAllNonFlowerSuccess(state, action) {
            state.nonflowers = action.payload;
        },
        getSingleExpeditonSampleSuccess(state, action) {
            state.expeditionsample = action.payload;
        },
        getAllExpeditionSamplesSuccess(state, action) {
            state.expeditionsamples = action.payload;
        },
        getPendingDestructionsSuccess(state, action) {
            state.pendingdestructions = action.payload;
        },
        getPendingExpeditonSamplesSuccess(state, action) {
            state.pendingexpeditionsamples = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export const transactionActions = slice.actions;

// ----------------------------------------------------------------------
export function getAllTransactions() {
    return async () => {
        try {
            const response = await axios.get('/api/transactions/index');
            dispatch(slice.actions.getAllTransactionsSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getSingleTransactions() {
    return async () => {
        try {
            const response = await axios.get('/api/transactions/show');
            dispatch(slice.actions.getSingleTransactionsSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getAllImportations() {
    return async () => {
        try {
            const response = await axios.get('/api/importations/show');
            dispatch(slice.actions.getAllImportationsSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getAllInstallationImportations(installationId) {
    return async () => {
        try {
            const response = await axios.get(`/api/installation-importations/show/${installationId}`);
            dispatch(slice.actions.getAllInstallationImportationsSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getSingleImportations(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/importations/show-single/${id}`);
            dispatch(slice.actions.getSingleImportTransactionSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function updateImportationData(data) {
    return dispatch(slice.actions.updateImportationDataSuccess(data));
}
export function getSingleDestruction(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/destructions/show/${id}`);
            dispatch(slice.actions.getSingleDestructionSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getAllDestructions() {
    return async () => {
        try {
            const response = await axios.get('/api/all-destructions');
            dispatch(slice.actions.getAllDestructionsSuccess(response.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getAllDestructionReasons() {
    return async () => {
        try {
            const response = await axios.get('/api/destruction-reasons');
            dispatch(slice.actions.getAllDestructionReasonsSuccess(response.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getPendingDestructions() {
    return async () => {
        try {
            const response = await axios.get('/api/pending-destructions');
            dispatch(slice.actions.getPendingDestructionsSuccess(response.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getBatchAllNonFlower(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/batch-non-flower/${id}`);
            dispatch(slice.actions.getBatchAllNonFlowerSuccess(response.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getAllNonFlower() {
    return async () => {
        try {
            const response = await axios.get(`/api/non-flower/`);
            dispatch(slice.actions.getAllNonFlowerSuccess(response.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}

export function getSingleExpeditionSample(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/expedition-sample/${id}`);
            dispatch(slice.actions.getSingleExpeditonSampleSuccess(response.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}

export function getAllExpeditionSamples() {
    return async () => {
        try {
            const response = await axios.get(`/api/expedition-samples/`);
            dispatch(slice.actions.getAllExpeditionSamplesSuccess(response.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getPendingExpeditionSamples() {
    return async () => {
        try {
            const response = await axios.get(`/api/pending-expedition-samples/`);
            dispatch(slice.actions.getPendingExpeditonSamplesSuccess(response.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
