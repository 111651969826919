import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { useParams } from 'react-router-dom';

// login routing
const ErrorPage = Loadable(lazy(() => import('views/Error')));

const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword3')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword3')));
// component that extracts the token parameter and renders the AuthResetPassword component
const ResetPasswordWrapper = () => {
    const { token } = useParams();
    return <AuthResetPassword token={token} />;
};

// login routing
const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/reset/:token',
            element: <ResetPasswordWrapper />
        },
        {
            path: '*',
            element: <ErrorPage />
        }
    ]
};

export default LoginRoutes;
