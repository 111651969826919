/* Route::get('container-models', [ContainerModelController::class, 'index']);
    Route::get('container-models/{id}', [ContainerModelController::class, 'show']); */

import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import axios from 'utils/axios';

// initial state
const initialState = {
    containermodels: [],
    containermodel: '',
    printingslip: false
};

// ==============================|| SLICE - CONTAINER MODELS ||============================== //

const settings = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        // get dashboard container models
        getContainerModelsSuccess(state, action) {
            state.containermodels = action.payload;
        },
        // get single container model
        getSingleContainerModelSuccess(state, action) {
            state.containermodel = action.payload;
        },
        // set printing slip
        setPrintingSlip(state, action) {
            state.printingslip = action.payload;
        }
    }
});

export default settings.reducer;

export function getContainerModels() {
    return async () => {
        try {
            const response = await axios.get('/api/container-models');
            dispatch(settings.actions.getContainerModelsSuccess(response.data.containers));
        } catch (error) {
            console.error(error);
        }
    };
}

export function getSingleContainerModel(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/container-models/${id}`);
            dispatch(settings.actions.getSingleContainerModelSuccess(response.data.container));
        } catch (error) {
            console.error(error);
        }
    };
}

export function setPrintingSlip(value) {
    return () => {
        dispatch(settings.actions.setPrintingSlip(value));
    };
}
