// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconPackgeImport, IconPackgeExport, IconFileShredder } from '@tabler/icons';

// constant
const icons = {
    IconPackgeImport,
    IconPackgeExport,
    IconFileShredder
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const transactions = {
    id: 'transaction-docs-roadmap',
    title: <FormattedMessage id="transactions" />,
    icon: icons.IconHelp,
    type: 'group',
    children: [
        {
            id: 'importation',
            title: <FormattedMessage id="importation" />,
            type: 'item',
            url: '/transactions/importation',
            icon: icons.IconPackgeImport,
            breadcrumbs: false
        },
        {
            id: 'expedition',
            title: <FormattedMessage id="expedition" />,
            type: 'item',
            url: '/transactions/expedition',
            icon: icons.IconPackgeExport,
            breadcrumbs: false
        },
        {
            id: 'destruction',
            title: <FormattedMessage id="destruction" />,
            type: 'item',
            url: '/transactions/destruction',
            icon: icons.IconFileShredder,
            breadcrumbs: false
        }
    ]
};

export default transactions;
